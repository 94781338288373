
function downloadImage(url) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';

    // Set CORS to '*'
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');

    xhr.onload = function () {
      if (xhr.status === 200) {
        const reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      } else {
        reject(new Error('Failed to download image'));
      }
    };

    xhr.onerror = function () {
      reject(new Error('Failed to download image'));
    };

    xhr.send();
  });
}

export default {
  downloadImage
};
