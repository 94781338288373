<template>
  <div>
    <BarraCrud
      titulo="Pedidos"
      :botaoNovo="true"
      :onFiltrar="filtrar"
      :onNovo="() => openForm(null)"
    />

    <b-table
      striped
      hover
      :items="lista"
      :fields="[
        { key: 'id', label: 'ID', sortable: true },
        { key: 'dt_pedido', label: 'Data', sortable: true },
        { key: 'dt_prevista', label: 'Previsão', sortable: true },
        { key: 'fornecedor', label: 'Fornecedor', sortable: true },
        { key: 'vl_total', label: 'Valor', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'acoes', label: 'Ações' },
      ]"
    >
      <template #cell(dt_pedido)="row">
        {{ row.value | moment("DD/MM/YYYY") }}
      </template>
      <template #cell(dt_prevista)="row">
        {{ row.value | moment("DD/MM/YYYY") }}
      </template>
      <template #cell(vl_total)="row">
        {{ row.value | currency }}
      </template>
      <template #cell(status)="row">
        <b-badge
          :variant="
            row.value == 'P' ? 'warning' : row.value == 'S' ? 'info' : 'success'
          "
        >
          {{
            row.value == "P"
              ? "Pendente"
              : row.value == "S"
              ? "Enviado p/ Fornecedor"
              : "Concluído"
          }}
        </b-badge>
      </template>
      <template #cell(acoes)="row">
        <b-button
          size="sm"
          @click="openEnvioFornecedor(row.item)"
          variant="info"
          :disabled="row.item.status == 'C'"
          class="mr-1"
        >
          <i class="fa fa-paper-plane"></i>
        </b-button>
        <b-button
          size="sm"
          @click="openEntrada(row.item)"
          :disabled="row.item.status == 'C'"
          variant="success"
          class="mr-1"
        >
          <i class="fa fa-people-carry"></i>
        </b-button>
        <b-button
          size="sm"
          @click="openForm(row.item)"
          variant="warning"
          class="mr-1"
        >
          <b-icon icon="pencil"></b-icon>
        </b-button>
        <b-button
          size="sm"
          variant="danger"
          @click="delPedido(row.item)"
          :disabled="row.item.status == 'C'"
        >
          <b-icon icon="trash"></b-icon>
        </b-button>
      </template>
    </b-table>

    <b-modal id="modal-pedido" size="xl" hide-footer title="Pedido de Estoque">
      <pedido-form :editObj="editObj" @close="closeForm" />
    </b-modal>
    <b-modal id="modal-entrada-estoque" hide-footer hide-title hide-header>
      <div v-if="editObj">
        <estoque-entrada-form
          :obj-inicial="{
            ...editObj,
            custo: editObj.cod_custo,
            fornecedor: editObj.fornecedor_id,
            id: null,
            pedido_id: editObj.id,
          }"
          @close="closeEntrada"
        />
      </div>
    </b-modal>
    <b-modal id="modal-print-pedido" size="lg" hide-footer hide-header>
      <div v-if="editObj">
        <pedido-envio-fornecedor :obj="editObj" @close="closeEnvioFornecedor" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import BarraCrud from "../../components/common/BarraCrud.vue";
import PedidoForm from "./PedidoForm.vue";

// import moment from "moment";

import EstoquePedidosLib from "../../libs/EstoquePedidosLib";
import EstoqueEntradaForm from "./EstoqueEntradaForm.vue";
import PedidoEnvioFornecedor from "./PedidoEnvioFornecedor.vue";

export default {
  components: {
    BarraCrud,
    PedidoForm,
    EstoqueEntradaForm,
    PedidoEnvioFornecedor,
  },
  props: {},
  mounted() {
    this.loadItems();
  },
  data() {
    return {
      editObj: null,
      lista: [],
      loading: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    openEnvioFornecedor(item) {
      this.editObj = item;
      this.$bvModal.show("modal-print-pedido");
    },
    closeEnvioFornecedor(done = false) {
      this.$bvModal.hide("modal-print-pedido");
      if (done) {
        this.$emit("close");
      }
    },
    closeEntrada() {
      this.$bvModal.hide("modal-entrada-estoque");
      this.loadItems();
    },
    openEntrada(item) {
      this.editObj = item;
      this.$bvModal.show("modal-entrada-estoque");
    },
    filtrarData(data) {
      console.log(data);
    },
    filtrar(filtros) {
      console.log(filtros);
    },
    openForm(row) {
      if (!row) {
        // row = {
        //   custo: null,
        //   fornecedor: null,
        //   gerarContas: false,
        //   vl_subtotal: 0,
        //   vl_total: 0,
        //   dtVencimento: moment().format("YYYY-MM-DD"),
        //   anexos: [],
        // };
      }
      console.log("row", Object.assign({}, { ...row }));
      this.editObj = row;
      this.$bvModal.show("modal-pedido");
    },
    closeForm() {
      this.$bvModal.hide("modal-pedido");
      this.loadItems();
    },
    async loadItems() {
      this.loading = true;
      try {
        let result = await EstoquePedidosLib.getEstoquePedidos();
        console.log("data", result);
        this.lista = result;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async delPedido(item) {
      let result = await this.$swal({
        title: "Tem certeza que deseja excluir?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });
      console.log(result);
      if (result.isConfirmed) {
        try {
          let res = await EstoquePedidosLib.delEstoquePedido(item.id);
          if (res && res.success) {
            this.$swal({
              title: "Excluído com sucesso!",
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
            this.loadItems();
          } else {
            this.$swal({
              title: "Erro ao excluir!",
              icon: "error",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
