<template>
  <div class="">
    <table class="table table-bordeless">
      <tr>
        <td class="text-center" style="width: 25%">
          <img :src="logoImg" width="100%" style="max-width: 100px" />
        </td>
        <td class="text-center">
          <h1 class="text-center">Pedido de Compra</h1>
          <h4 class="text-center">
            {{ $store.state.empresa_ativa.fantasia }}
          </h4>
        </td>
        <td class="text-right" style="width: 25%">
          Data Impressão
          <br />
          {{ data | moment("DD/MM/YYYY HH:mm:ss") }}
        </td>
      </tr>
      <tr>
        <td colspan="999" class="p-0">
          <table class="w-100 text-center" style="font-size: 12px">
            <tr>
              <td class="p-0">
                <div class="text-white bg-dark p-2">Número Pedido</div>
              </td>
              <td class="text-white bg-dark p-0">
                <div class="text-white bg-dark p-2">Data Pedido</div>
              </td>
              <td class="text-white bg-dark p-0">
                <div class="text-white bg-dark p-2">Pevisão de Entrega</div>
              </td>
            </tr>
            <tr>
              <td>{{ obj.id }}</td>
              <td>{{ obj.dt_pedido | moment("DD/MM/YYYY") }}</td>
              <td>{{ obj.dt_previsao | moment("DD/MM/YYYY") }}</td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td colspan="9999" class="p-0">
          <div class="w-100 p-2 text-white bg-dark">Fornecedor</div>
          <table
            class="w-100"
            style="font-size: 10px"
            v-if="fornecedor && fornecedor.razao_social"
          >
            <tr>
              <td width="60%">
                <b>Razão Social - Fantasia</b>
                <br />
                {{ fornecedor.razao_social }}
                <br />
                {{ fornecedor.fantasia }}
              </td>
              <td>
                <b>CNPJ</b>
                <br />
                {{ fornecedor.cnpj }}
              </td>
            </tr>
            <tr>
              <td>
                <b>E-mail</b>
                <br />
                {{ fornecedor.email }}
              </td>
              <td>
                <b>Telefone</b>
                <br />
                {{ fornecedor.telefone }}
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td colspan="9999" class="p-0">
          <div class="w-100 p-2 text-white bg-dark">Itens</div>
          <table class="w-100" style="font-size: 10px">
            <tr>
              <td width="60%">
                <b>Produto</b>
              </td>
              <td>
                <b>Quantidade</b>
              </td>
              <td>
                <b>Valor Unitário</b>
              </td>
              <td>
                <b>Valor Total</b>
              </td>
            </tr>
            <tr v-for="(item, idx) in obj.produtos" :key="idx">
              <td>
                {{ item.produto.nome }}
                <br />
                {{ item.descricao }}
              </td>
              <td class="text-center">{{ item.qtd }}</td>
              <td class="text-center">{{ item.vl_unitario }}</td>
              <td class="text-center">
                {{ item.vl_total | currencyMask }}
              </td>
            </tr>
            <tr>
              <td colspan="999" class="text-right">
                <b>Valor Total: {{ obj.vl_total | currencyMask }}</b>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td colspan="999" class="p-0">
          <div class="">
            <div class="w-100 p-2 text-white bg-dark">Observações</div>
            <div class="p-4">{{ obj.observacao }}</div>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="999" class="p-0">
          <!--- exibir totais com desconto-->
          <div class="w-100 p-2 text-white bg-dark">Totais</div>
          <table class="w-100 text-center" style="font-size: 13px">
            <tr>
              <td class="p-0">
                <div class="bg-primary text-white w-100 p-2">
                  <b>Valor Total</b>
                </div>
              </td>
              <td class="p-0">
                <div class="bg-primary text-white w-100 p-2">
                  <b>Desconto</b>
                </div>
              </td>
              <td class="p-0">
                <div class="bg-primary text-white w-100 p-2">
                  <b>Valor Final</b>
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-center">
                {{ obj.vl_subtotal | currencyMask }}
              </td>
              <td class="text-center">
                {{ obj.vl_desconto }}
              </td>
              <td class="text-center">
                {{ obj.vl_total | currencyMask }}
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td colspan="999999">
          <div class="d-flex justify-content-around">
            <div class="text-center">
              <b>Previsão de Pagamento</b><br />
              {{ obj.dt_previsao_pagamento | moment("DD/MM/YYYY") }}
            </div>
            <div class="text-center">
              <b>Condição de Pagamento</b><br />
              {{ obj.condicao_pagamento }}
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import imageHelper from "../../utils/imageHelper";
export default {
  components: {},
  props: {
    obj: Object,
    fornecedor: Object,
  },
  mounted() {
    imageHelper.downloadImage(this.logoImg).then((r) => {
      // console.log("IMG URL", r);
      this.logoImg = r;
    });
  },
  data() {
    return {
      data: new Date(),
      logoImg: this.$store.state.empresa_ativa.logo,
    };
  },
  watch: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
