<template>
  <div>
    <div v-if="loading" class="p-5 text-center">
      <b-spinner></b-spinner>
    </div>
    <div class="row" v-if="obj && !loading">
      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-12 col-sm-6">
            <b-form-group label="Data Pedido">
              <b-input
                v-model="obj.dt_pedido"
                type="date"
                :disabled="obj.status == 'C'"
              ></b-input>
            </b-form-group>
          </div>
          <div class="col-12 col-sm-6">
            <b-form-group label="Status">
              <span
                class="form-control text-white text-center"
                :class="
                  obj.status == 'P'
                    ? 'bg-warning'
                    : obj.status == 'S'
                    ? 'bg-info'
                    : 'bg-success'
                "
              >
                {{
                  obj.status == "P"
                    ? "Pendente"
                    : obj.status == "S"
                    ? "Enviado"
                    : "Concluído"
                }}
              </span>
            </b-form-group>
          </div>
          <div class="col-12 pr-0 pr-sm-3 pl-0 pl-sm-3">
            <select-centro-custo
              :custoId="obj.cod_custo"
              :showTitle="true"
              :disabled="obj.status == 'C'"
              @change="(ev) => (obj.cod_custo = ev)"
            />
          </div>
          <div class="col-12 pr-0 pr-sm-3 pl-0 pl-sm-3 mt-3">
            <select-fornecedor
              :fornecedorId="obj.fornecedor_id"
              :showTitle="true"
              :disabled="obj.status == 'C'"
              @change="
                (ev) => {
                  obj.fornecedor_id = ev;
                }
              "
            />
          </div>
          <div class="col-12 py-4 px-4">
            <b-form-group>
              <b-form-textarea
                v-model="obj.obs"
                placeholder="Observação"
                :disabled="obj.status == 'C'"
                rows="3"
              />
            </b-form-group>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-12 col-sm-6">
                <b-form-group label="Previsão Entrega">
                  <b-input
                    type="date"
                    v-model="obj.dt_prevista"
                    :disabled="obj.status == 'C'"
                  />
                </b-form-group>
              </div>
              <div class="col-12 col-sm-6">
                <b-form-group label="Data de Entrega">
                  <b-input type="date" v-model="obj.dt_entrega" disabled />
                </b-form-group>
              </div>
              <!-- <div class="col-12 col-sm-4">
                <b-form-group label="Recebimento">
                  <b-input type="date" v-model="obj.dt_recebimento" disabled />
                </b-form-group>
              </div> -->
            </div>
          </div>

          <div class="col-12">
            <hr />
            <b-card>
              <!-- <div class="col-8 col-sm-6 p-3">
                  <b-check switch v-model="obj.gerarContas" :value="true"
                    >Gerar Contas Á Pagar</b-check
                  >
                </div> -->
              <b class=""><b-icon-files class="mr-2" />Anexos</b>

              <file-upload
                class="mt-3"
                :enableTitle="true"
                :value="obj.anexos"
                :enable-edit="obj.status !== 'C'"
              />
            </b-card>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <div class="row">
          <div class="col-12 pr-0 pr-sm-3 pl-0 pl-sm-3">
            <b-form-group label="Produtos" v-if="produtos">
              <v-select
                :options="produtos"
                label="nome"
                v-model="item.produto"
              />
              <div class="row mt-2">
                <div class="col-6 pr-1">
                  <b-form-group label="Valor Unitário">
                    <b-input
                      v-model="item.vl_unitario"
                      pattern="\d*"
                      v-money="moneyMask"
                      :disabled="obj.status == 'C'"
                    />
                  </b-form-group>
                </div>
                <div class="col-6 pl-1 text-center">
                  <b-form-group label="Qtd.">
                    <input
                      pattern="\d*"
                      type="number"
                      ref="txtQtd"
                      v-model="item.qtd"
                      class="form-control"
                      :disabled="obj.status == 'C'"
                      F
                    />
                    <div
                      v-if="item.vl_total && item.vl_total > 0"
                      class="text-right pr-2 pt-3 pb-0 mb-0"
                    >
                      <h5 class="pb-0 mb-0">
                        Total: {{ item.vl_total | currencyMask }}
                      </h5>
                    </div>
                  </b-form-group>
                </div>
              </div>
            </b-form-group>
            <b-btn
              block
              variant="success"
              @click="addItem"
              :disabled="obj.status == 'C'"
              ><b-icon-plus /> Adicionar</b-btn
            >
          </div>
          <div class="col-12 mt-3">
            <label><b>Lista de Itens</b></label>
            <b-table :fields="camposItens" stacked="sm" :items="itens">
              <template #cell(vl_total)="row">
                <div class="text-right">
                  {{ row.item.vl_total | currencyMask }}
                </div>
              </template>
              <template #cell(vl_unitario)="row">
                <b-input
                  v-model="row.item.vl_unitario"
                  pattern="\d*"
                  v-money="moneyMask"
                  class="text-center"
                  style="max-width: 100px"
                  :disabled="obj.status == 'C'"
                />
              </template>
              <template #cell(qtd)="row">
                <b-input
                  v-model="row.item.qtd"
                  type="number"
                  class="text-center"
                  style="max-width: 80px"
                  :disabled="obj.status == 'C'"
                />
              </template>
              <template #cell(opc)="row">
                <b-btn
                  small
                  size="sm"
                  @click="delItem(row.item)"
                  variant="danger"
                  :disabled="obj.status == 'C'"
                  ><b-icon-trash
                /></b-btn>
              </template>
            </b-table>
            <hr />
            <div>
              <b-row>
                <b-col cols="6" class="text-right">
                  <b>Subtotal</b>
                </b-col>
                <b-col cols="6" class="text-right">
                  <b>{{ obj.vl_subtotal | currencyMask }}</b>
                </b-col>
                <b-col cols="12" class="text-right">
                  <hr />
                </b-col>
                <b-col cols="6" class="text-right">
                  <b>Desconto</b>
                </b-col>
                <b-col cols="6" class="text-right">
                  <b-input
                    v-model="obj.vl_desconto"
                    pattern="\d*"
                    v-money="moneyMask"
                    style="max-width: 100px"
                    class="ml-auto text-right"
                  />
                </b-col>
                <b-col cols="12" class="text-right">
                  <hr />
                </b-col>
                <b-col cols="6" class="text-right">
                  <b>Total</b>
                </b-col>
                <b-col cols="6" class="text-right">
                  <b>{{ obj.vl_total | currencyMask }}</b>
                </b-col>
              </b-row>
            </div>
          </div>
          <div class="col-12">
            <hr />
            <b-row>
              <b-col cols="6">
                <b-form-group label="Previsão Pagamento">
                  <b-input
                    type="date"
                    v-model="obj.dt_previsao_pagamento"
                    :disabled="obj.status == 'C'"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Condição de Pagamento">
                  <b-input
                    v-model="obj.condicao_pagamento"
                    :disabled="obj.status == 'C'"
                    placeholder="ex. 30/60/90"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>

      <div class="col-12 d-flex justify-content-between mt-4">
        <div>
          <b-btn
            variant="info"
            @click="openEnvioFornecedor"
            :disabled="obj.status === 'C' || !obj.id"
            ><i class="fa fa-paper-plane" /> Enviar p/ Fornecedor</b-btn
          >
          <b-btn
            variant="success"
            @click="entradaEstoque"
            class="ml-2"
            :disabled="!obj.id"
            ><i class="fa fa-people-carry" /> Entrada Estoque</b-btn
          >
        </div>
        <div>
          <b-btn
            variant="success"
            @click="salvar"
            class="mr-2"
            :disabled="obj.dt_entrega"
            ><i class="fa fa-save" /> Salvar Pedido</b-btn
          >
          <b-btn variant="secondary" @click="close"
            ><i class="fa fa-ban" /> Cancelar</b-btn
          >
        </div>
      </div>
    </div>

    <b-modal id="modal-entrada-estoque" hide-footer hide-title hide-header>
      <estoque-entrada-form
        :obj-inicial="{
          ...obj,
          custo: obj.cod_custo,
          fornecedor: obj.fornecedor_id,
          id: null,
          pedido_id: obj.id,
        }"
        @close="closeEntrada"
      />
    </b-modal>
    <b-modal
      id="modal-envio-fornecedor"
      size="lg"
      hide-title
      hide-header
      hide-footer
    >
      <pedido-envio-fornecedor :obj="obj" @close="closeEnvioFornecedor" />
    </b-modal>
  </div>
</template>

<script>
import SelectCentroCusto from "../../components/Financeiro/SelectCentroCusto.vue";
import SelectFornecedor from "../../components/Fornecedores/SelectFornecedor.vue";

import moment from "moment";
import FileUpload from "../../components/common/FileUpload.vue";

import EstoquePedidosLib from "../../libs/EstoquePedidosLib";
import EstoqueLib from "../../libs/EstoqueLib";
import EstoqueEntradaForm from "./EstoqueEntradaForm.vue";
import PedidoEnvioFornecedor from "./PedidoEnvioFornecedor.vue";

export default {
  components: {
    SelectFornecedor,
    SelectCentroCusto,
    FileUpload,
    EstoqueEntradaForm,
    PedidoEnvioFornecedor,
  },
  props: {
    editObj: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.carregarProdutos();
  },
  data() {
    return {
      loading: false,
      produtos: [],
      itens: [],
      obj: {
        cod_custo: null,
        fornecedor_id: null,
        vl_subtotal: 0,
        vl_desconto: 0,
        dt_pedido: moment().format("YYYY-MM-DD"),
        dt_envio: null,
        status: "P",
        vl_total: 0,
        // dtVencimento: moment().format("YYYY-MM-DD"),
        anexos: [],
        dt_finalizacao: null,
        dt_cancelamento: null,
        dt_recebimento: null,
        dt_entrega: null,
        dt_prevista: null,
        dt_previsao_pagamento:  moment().add(7,'d').format("YYYY-MM-DD"),
        condicao_pagamento: null,
      },
      item: {
        produto: null,
        qtd: 1,
        vl_unitario: 0,
        vl_total: 0,
      },
      camposItens: [
        { key: "produto.nome", label: "Produto" },
        { key: "vl_unitario", label: "Valor" },
        { key: "qtd", label: "Qtd" },
        { key: "vl_total", label: "Total" },
        { key: "opc", label: "" },
      ],
      moneyMask: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: true,
      },
    };
  },
  watch: {
    itens: {
      handler: function (val) {
        console.log("itens", val);
        for (let it of val) {
          it.vl_total =
            parseFloat(this.formatValorReal(it.vl_unitario)) *
            parseFloat(it.qtd);
        }
        let total = 0;
        val.forEach((x) => {
          total += x.vl_total;
        });
        this.obj.vl_subtotal = total;
        this.obj.vl_total = total - this.obj.vl_desconto;
      },
      deep: true,
    },
    "obj.vl_subtotal": function (val) {
      // console.log(val,this.formatValorReal(val||"0,00"), this.formatValorReal(this.obj.vl_desconto||"0,00"))
      this.obj.vl_total =
        this.formatValorReal(val || "0,00") -
        this.formatValorReal(this.obj.vl_desconto || "0,00");
    },
    "obj.vl_desconto": function (val) {
      // console.log(val, this.obj.vl_subtotal)
      this.obj.vl_total =
        this.obj.vl_subtotal - this.formatValorReal(val || "0,00");
    },
    "obj.dt_pedido": function () {
      if (!this.obj.id) {
        try {
          this.obj.dt_previsao_pagamento = moment(this.obj.dt_pedido)
            .add(7, "days")
            .format("YYYY-MM-DD");
        } catch (error) {
          console.log(error)
        }
      }
    }
  },
  computed: {},
  methods: {
    openEnvioFornecedor() {
      this.$bvModal.show("modal-envio-fornecedor");
    },
    closeEnvioFornecedor(done = false) {
      this.$bvModal.hide("modal-envio-fornecedor");
      if (done) {
        this.$emit("close");
      }
    },
    closeEntrada(done = false) {
      this.$bvModal.hide("modal-entrada-estoque");
      if (done) {
        this.$emit("close");
      }
    },
    entradaEstoque() {
      this.$bvModal.show("modal-entrada-estoque");
    },
    async calculaValorItem() {
      await this.$nextTick();

      // console.log("this.item", this.item);
      if (this.item && this.item.qtd && this.item.vl_unitario) {
        this.item.vl_subtotal =
          parseFloat(this.item.qtd) *
          this.formatValorReal(this.item.vl_unitario);
      }

      await this.$nextTick();
    },
    async carregarProdutos() {
      this.loading = true;
      this.produtos = await EstoqueLib.getProdutos({});
      console.log("prods", this.produtos);
      setTimeout(() => {
        this.$forceUpdate();
        if (this.editObj && this.editObj.id) {
          this.obj = this.editObj;
          console.log("obj", this.obj);
          if (this.obj.produtos) {
            this.itens = this.obj.produtos;
          }
        }
        this.loading = false;
      }, 1000);
    },
    async addItem() {
      let err = [];
      if (!this.item.produto) {
        err.push("Produto");
      }
      if (!this.item.qtd || this.item.qtd == "" || this.item.qtd <= 0) {
        err.push("Quantidade");
      }
      //   if (
      //     !this.item.vl_total ||
      //     this.item.vl_total == "" ||
      //     this.item.vl_total < 0
      //   ) {
      //     err.push("Valor");
      //   }

      if (err.length > 0) {
        this.$swal({
          title: "Atenção",
          html: `<div class="text-left">Verifique os campos <br/><small><b>${err.map(
            (it) => {
              return `${it}<br/>`;
            }
          )}</b></small></div>`,
          toast: true,
          showConfirmButton: false,
          position: "top-end",
          icon: "error",
          timer: 1200,
        });
      } else {
        await this.calculaValorItem();
        let obj = Object.assign(this.item, {});
        obj.id = this.itens.length + 1 + this.item.produto.cod_produto;
        // console.log(obj, this.itens);
        this.itens.push(obj);
        // console.log(this.itens);
        this.item = {
          produto: null,
          qtd: 1,
          vl_unitario: 0,
          vl_total: 0,
        };
        this.$forceUpdate();
      }
    },
    delItem(row) {
      // console.log("del", row);
      this.itens = this.itens.filter((x) => x.id != row.id);
      // console.log(this.itens);
      this.$forceUpdate();
    },
    close() {
      this.$emit("close");
    },
    async salvar() {
      console.log(this.obj);
      let err = [];
      if (this.itens.length <= 0) {
        err.push("Adicione um ou mais itens");
      }
      if (!this.obj.fornecedor_id) {
        err.push("Selecione um fornecedor!");
      }
      if (!this.obj.cod_custo) {
        err.push("Selecione um centro de custo!");
      }

      if (err.length > 0) {
        this.$swal({
          title: "Atenção",
          html: `<div class="text-left">${err.join("<hr/>")}</div>`,
          icon: "error",
        });
      } else {
        this.obj = { ...this.obj, itens: this.itens };
        this.obj.vl_desconto = this.formatValorReal(this.obj.vl_desconto);
        // console.log(11111,this.obj)
        let result = await EstoquePedidosLib.storeEstoquePedidos(this.obj);
        let erro = [];
        try {
          if (result.success) {
            erro = [];
            this.$swal({
              title: "Concluído com sucesso!",
              icon: "success",
              toast: true,
              showConfirmButton: false,
              position: "top-end",
              timer: 2000,
            });
            this.$emit("close");
          } else {
            this.$swal({
              title: "Atenção",
              html: `<div class="text-left">Erro ao Salvar ${result.message}</div>`,
              icon: "error",
            });
          }
        } catch (er) {
          console.log(er);
          erro.push(erro);
        }

        if (erro.length <= 0) {
          this.$emit("close");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
