import axios from '@/utils/axios';

const getEstoquePedidos = async (filter) => {
  try {
    return ((await axios.post('estoque_pedidos/get', filter)).data).data;
  } catch (err) {
    console.log("ERRO AO BSUCAR PRODUTOS ESTOQUE", err);
    return [];
  }
}

const storeEstoquePedidos = async (dados) => {
  try {
    return (await axios.post('estoque_pedidos/store', dados)).data;
  } catch (e) {
    console.log("ERRO AO REALIZAR ENTRADA", e);
    return null;
  }
}

const delEstoquePedido = async (id) => {
  try {
    return (await axios.post('estoque_pedidos/delete', { id })).data;
  } catch (e) {
    console.log("ERRO AO EXCLUIR ENTRADA", e);
    return null;
  }
}

export default {
  getEstoquePedidos,
  storeEstoquePedidos,
  delEstoquePedido
}
