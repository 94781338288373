<template>
  <div>
    <div v-if="obj && obj.id">
      <vue-html2pdf
        v-if="downloadPDF"
        crossorigin="*"
        :paginate-elements-by-height="1400"
        :filename="`Pedido Compra ${obj.id}`"
        :pdf-quality="2"
        pdf-format="a4"
        :enable-download="!sendingWpp"
        pdf-orientation="portrait"
        pdf-content-width="800px"
        ref="html2Pdf"
        :image="{ type: 'jpeg', quality: 0.95 }"
        @beforeDownload="downloadedPDF"
      >
        <section slot="pdf-content">
          <div class="bg-white p-3">
            <pedido-impressao :obj="obj" :fornecedor="fornecedor" />
          </div>
        </section>
      </vue-html2pdf>
      <h2 class="text-center">Pedido nº {{ obj.id }}</h2>
      <hr />
      <div id="divPrint">
        <pedido-impressao :obj="obj" :fornecedor="fornecedor" />
      </div>
      <div class="d-flex justify-content-between">
        <b-btn class="mr-2" block variant="primary" @click="print"
          ><b-icon-printer class="mr-1" /> Imprimir Pedido</b-btn
        >
        <b-btn
          class="mr-2"
          block
          variant="primary"
          :disabled="loadingDownload"
          @click="download"
        >
          <span v-if="loadingDownload"> <b-spinner small /> Aguarde... </span>
          <span v-if="!loadingDownload">
            <b-icon-download class="mr-1" /> Download Pedido
          </span>
        </b-btn>
        <b-btn class="mr-2" block variant="primary" @click="showSendWpp"
          ><b-icon-whatsapp class="mr-1" /> Enviar via Whatsapp</b-btn
        >
        <b-btn class="mr-2" block variant="primary"
          ><b-icon-envelope class="mr-1" /> Enviar via E-mail</b-btn
        >
      </div>
      <b-btn size="lg" block variant="secondary" class="mt-2" @click="close"
        ><b-icon-door-closed class="mr-1" /> Fechar</b-btn
      >
    </div>
    <div v-else>
      <h2>Pedido não encontrado!</h2>
      <b-btn @click="close" block class="mt-5" color="danger">Fechar</b-btn>
    </div>
    <b-modal id="modal-send-wpp" title="Enviar via Whatsapp" hide-footer>
      <b>Enviar para número</b>
      <b-form-group label="Whatsapp">
        <b-input
          v-model="numberToWpp"
          v-mask="['(##)#####-####', '(##)####-####']"
        />
      </b-form-group>
      <b-form-group label="Mensagem (opcional)">
        <b-textarea v-model="messageToWpp" />
      </b-form-group>
      <b-btn block variant="primary" @click="sendWpp" :disabled="sendingWpp">
        <span v-if="sendingWpp"> <b-spinner small /> Aguarde... </span>
        <span v-if="!sendingWpp">
          <b-icon-whatsapp class="mr-1" /> Enviar
        </span>
      </b-btn>
    </b-modal>
  </div>
</template>

<script>
import FornecedoresLib from "../../libs/FornecedoresLib";
import WhatsAppService from "../../libs/WhatsAppService";
import PrinterWindowHelper from "../../utils/PrinterWindowHelper";
import PedidoImpressao from "./PedidoImpressao.vue";
import VueHtml2pdf from "vue-html2pdf";
export default {
  components: { PedidoImpressao, VueHtml2pdf },
  props: {
    obj: {
      type: Object,
    },
  },
  mounted() {
    if (this.obj) {
      this.loadFornecedor();
      this.messageToWpp = `Olá, segue em anexo o pedido de compra nº ${this.obj.id} para sua análise. - ${this.user.nome}`;
    }
  },
  data() {
    return {
      fornecedor: null,
      data: new Date(),
      downloadPDF: false,
      loadingDownload: false,
      numberToWpp: "",
      sendingWpp: false,
      messageToWpp: "",
    };
  },
  watch: {},
  computed: {
    nomeFornecedor() {
      if (this.fornecedor) {
        return this.fornecedor.razao_social + " - " + this.fornecedor.fantasia;
      }
      return "";
    },
  },
  methods: {
    async download() {
      // var opt = {
      //   margin: 0,
      //   filename: "time_sheet_report.pdf",
      //   image: { type: "jpeg", quality: 0.2 },
      //   html2canvas: { scale: 2, useCORS: true },
      //   jsPDF: { unit: "in", format: "a4", orientation: "p" },
      // };

      // html2pdf().set(opt).from(document.querySelector("#divPrint")).save();

      this.loadingDownload = true;
      this.downloadPDF = true;
      await this.$forceUpdate();
      await this.$nextTick();
      setTimeout(async () => {
        await this.$refs.html2Pdf.generatePdf();
        this.loadingDownload = false;
      }, 3000);
    },
    async print() {
      await PrinterWindowHelper(document.querySelector("#divPrint").innerHTML);
    },
    close() {
      this.$emit("close");
    },
    showSendWpp() {
      this.numberToWpp = this.fornecedor.telefone;
      this.$bvModal.show("modal-send-wpp");
    },
    async downloadedPDF({ html2pdf, options, pdfContent }) {
      // console.log("all", all);
      if (this.sendingWpp) {
        try {
          await html2pdf()
            .set(options)
            .from(pdfContent)
            .toPdf()
            .get("pdf")
            .then(async (pdf) => {
              //console.log("pdf", pdf);
              try {
                let number = this.numberToWpp.replace(/\D/g, "");
                if (this.messageToWpp && this.messageToWpp.trim() != "") {
                  await WhatsAppService.enviarTextoSimples(
                    number,
                    this.messageToWpp
                  );
                }

                let resultWpp = await WhatsAppService.enviarArquivo(
                  number.toString(),
                  btoa(pdf.output()),
                  "Pedido Compra " + this.obj.id,
                  "pdf"
                );
                console.log("resultWpp", resultWpp);
                if (resultWpp) {
                  this.$swal({
                    title: "Sucesso!",
                    text: "Pedido enviado com sucesso!",
                    icon: "success",
                    button: "OK",
                  });
                  this.$emit("close");
                } else {
                  this.$swal({
                    title: "Erro!",
                    text: "Erro ao enviar pedido!",
                    icon: "error",
                    button: "OK",
                  });
                }
              } catch (error) {
                console.log(error);
                this.$swal({
                  title: "Erro!",
                  text: "Erro ao enviar pedido!",
                  icon: "error",
                  button: "OK",
                });
              }

              this.sendingWpp = false;
            });
        } catch (error) {
          console.log(error);
          this.sendingWpp = false;
        }
      }
    },
    async sendWpp() {
      this.sendingWpp = true;
      this.downloadPDF = true;
      await this.$forceUpdate();
      await this.$nextTick();
      setTimeout(async () => {
        try {
          let res = await this.$refs.html2Pdf.generatePdf();
          console.log("res", res);
        } catch (error) {
          console.log(error);
          this.sendingWpp = false;
        }
      }, 3000);
    },
    async loadFornecedor() {
      try {
        let result = await FornecedoresLib.getFornecedores(
          {},
          this.obj.fornecedor_id
        );
        console.log("result", result);
        if (result && result.length > 0) {
          this.fornecedor = result[0];
        }
      } catch (error) {
        console.log(error);
      }
    },
    openPreview() {
      this.$bvModal.show("modal-pedido-view");
    },
  },
};
</script>

<style lang="scss" scoped></style>
